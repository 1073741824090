@import 'variables';

.section-1{
    margin-top: 30px;
    justify-content: space-between;
    .section-1-left{
        width: 60%;
    }
    .section-1-right{
        width: 40%;
        justify-content: end;
        min-width: 375px;
    }
    .image-showcase{
        max-width: 350px;
        margin-left: 50px;
        img{
            width: 100%;
        }
    }
}
@media (max-width: 755px) {
    .section-1{
        flex-direction: column-reverse;
        .section-1-left{
            width: 100%;
        }
        .section-1-right{
            width: 100%;
            min-width: 300px;
        }
        .image-showcase{
            margin-left: auto;
            max-width: 300px;
            margin-right: auto;
        }
    }
    .botton-box{

    }
    .head-box{
        text-align: center;
    }
}

.head-box{
    font-size: 40px;
    font-weight: 700;
}

.under-head-box{
    margin-top: 15px;
    font-size: 16px;
    color: #888;
}

.sub-box{
    margin-top: 10px;
    text-align: center;
    color: #888;
}

.botton-box{
    margin-top: 30px;
    .btn{
        &:not(:first-child){
            margin-left:20px;
        }
    }
}
@media (max-width: 755px) {
    .botton-box{
        justify-content: center;
    }
}

.social-box{
    margin-bottom: 10px;
    .social-item {
        &:not(:first-child){
            padding-left: 10px;
        }
        font-size: 16px;
        cursor: pointer;

        &:hover{
            opacity: 0.5;
        }
    }
}
@media (max-width: 755px) {
    .social-box{
        justify-content: center;
        margin-top: 10px;
    }
}

.common-header{
    font-size: 35px;
    font-weight: 600;
    padding: 30px 0;
}

.sk-group{
    flex-wrap: wrap;
    .sk-box{
        position: relative;
        width: 80px;
        text-align: center;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 10px;
        border-radius: 5px;
        box-shadow: 3px 11px 18px 1px rgba(212, 211, 211, 0.74);
        -webkit-box-shadow: 3px 11px 18px 1px rgba(212, 211, 211, 0.74);
        -moz-box-shadow: 3px 11px 18px 1px rgba(212, 211, 211, 0.74);
        &:not(:last-child){
            margin-right: 30px;
        }
        .sk-clip{
            position: absolute;
            width: 25px;
            height: 25px;
            top: -10px;
            left: -10px;
            font-size: 20px;
            color: $primary-theme-color;
        }
        .sk-ic{
            width: 60px;
            height: 60px;
            padding: 8px;
            border-radius: 5px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .sk-txt{
            padding-top: 10px;
            font-size: 14px;
            font-weight: 600;
        }
    }
}