@import 'variables';
.container{
    padding-left: 7%;
    padding-right: 7%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 970px) {
    .container {
        padding-left: 4%;
        padding-right: 4%;
    }
}

.d-flex{
    display: flex;
}
.jfc-c{
    justify-content: center;
}
.alt-c{
    align-items: center;
}
