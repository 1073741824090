@import 'variables';

.nav-o{
    width: 100%;
    height: 70px;
    
    .nav-i{
        height: 100%;
        padding-left: 7%;
        padding-right: 7%;
        display: flex;
        justify-content: space-between;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        border-bottom: 1px solid $default-separator-color;

        .nav-i-center{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .nav-i-right{
            display: flex;
            justify-content: end;
            align-items: center;
        }

        .nav-i-left{
            display: flex;
            justify-content: start;
            align-items: center;
        }
    }
}

@media (max-width: 970px) {
    .nav-o {
        .nav-i {
            padding-left: 4%;
            padding-right: 4%;
            height: 100%;
        }
    }
}

@media (max-width: 745px) {
    .nav-o {
        .nav-i {
            .nav-i-center{
                display: none;
            }
        }
    }
}

.nav-menu{
    padding: 0px 10px;
    cursor: pointer;

    &:hover{
        color: $primary-theme-color;
    }

    &.active{
        color: $primary-theme-color;
        pointer-events: none;
    }
}

.nav-logo{
    height: 55px;

    img{
        height: 100%;
    }
}
    