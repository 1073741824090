$primary-theme-color: #e22d6e;
$secondary-theme-color: #f6c967;
$blue-theme-color: #333159;
$default-text-color: #404040;
$default-border-color: #d9d9d9;
$default-separator-color: #9999992e;

$gradient-bg1-norm: rgb(226,45,110);
$gradient-bg1-grad: linear-gradient(90deg, rgba(226,45,110,1) 22%, rgba(246,201,103,1) 100%);

//755